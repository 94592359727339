/* Site Fonts */
@font-face {
    font-family: "DIN-Bold";
    src: url("./assets/fonts/DINB____.TTF") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "DIN-Regular";
    src: url("./assets/fonts/DINR____.TTF") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "DIN-Medium";
    src: url("./assets/fonts/DIN Medium.ttf") format("truetype");
    font-display: swap;
}




/* Global App Parameters */
.App {
    background-color: #0F1120;
}

.Page-Background-Container {
    min-height: calc(100vh - 160px);
    min-height: calc(100svh - 160px);
    display: flex;
    flex: column;
    justify-content: center;
}

.Page-Content-Container-1 {
    max-width: 1300px;
    margin-top: 4rem;
    margin-bottom: 8rem;
    margin-left: 2rem;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.Page-Content-Container-2 {
    display: flex;
    flex-direction: column;
}

.Page-Content-Column-1 {
    display: flex;
    flex-direction: column;
}

.Page-Content-Column-2 {
    display: flex;
    flex-direction: column;
}

.Page-Content-Column-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.Page-Content-Column-4 {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Element-Centered {
    display: flex;
    justify-content: center;
}

.Horizontal-Section-Divider {
    position: relative;
    border-top: 1px solid #FFFFFF;
    margin-top: 9rem;
    margin-bottom: 9rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

.Global-Button-Icon {
    color: #FFE443;
    opacity: 100%;
}

.fade-in {
    opacity: 0;
    transition: opacity 0.4s ease-in;
}

.fade-in.active {
    opacity: 1;
}

@media (min-width: 1000px) {
    .Page-Content-Container-2 {
        flex-direction: row;
        justify-content: space-between;
        gap: 10%;
    }

    .Page-Content-Column-2 {
        display: flex;
        flex-direction: column;
        max-width: 45%;
    }
}




/* Text Classes */
.H1-Header {
    font-family: DIN-Bold;
    font-size: 60px;
    text-align: left;
    color: #FFFFFF;
    user-select: none;
}

.H2-Header {
    font-family: DIN-Bold;
    font-size: 40px;
    text-align: left;
    color: #FFFFFF;
    user-select: none;
}

.H3-Header {
    font-family: DIN-Regular;
    font-size: 40px;
    text-align: left;
    color: #FFFFFF;
    user-select: none;
}

.H4-Header {
    font-family: DIN-Regular;
    font-size: 30px;
    color: #FFFFFF;
    margin-top: 30px;
    margin-bottom: 14px;
}

.Body-Text {
    font-family: DIN-Regular;
    font-size: 18px;
    color: #FFFFFF;
    list-style: disc;
}

.Body-Text-Bold {
    font-family: DIN-Bold;
    font-size: 18px;
    color: #FFFFFF;
}

.Body-Text-Right {
    font-family: DIN-Regular;
    justify-content: right;
    font-size: 18px;
    color: #FFFFFF;
}

a {
    color: #FFE443;
    text-decoration: none;
}

a:hover {
    color: #FFEE8E;
}

a.custom-link {
    color: #FFE443;
    text-decoration: none;
    cursor: pointer;
}

a.custom-link:hover {
    color: #FFEE8E;
}




/* Component Classes */
.Navlinks-Box {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
}

.Navlinks {
    margin-left: 30px;
    font-family: DIN-Regular;
    color: #FFFFFF;
    font-size: 20px;
}

.Navlinks.active {
    color: #FFE443;
}

.Navlinks:hover {
    color: #FFE443;
}

.Navbar-Brand {
    font-family: DIN-Medium;
    color: #FFFFFF;
    font-size: 30px;
    margin-left: 20px;
}

.Navbar-Brand.active {
    color: #FFE443;
}

.Navbar-Brand:hover {
    color: #FFE443;
}

.Navbar-Visual {
    height: 80px;
}

.Navbar-Drawer-Icon {
    color: #FFFFFF;
}

.Footer-Visual {
    height: 80px;
}

.Footer-Text {
    font-family: DIN-Regular;
    font-size: 16px;
    color: #FFFFFF;
    margin-left: 20px;
    display: inline;
    user-select: none;
}

.Footer-Text-Right {
    font-family: DIN-Regular;
    font-size: 16px;
    color: #FFFFFF;
    justify-content: right;
}

.Footer-Text,
.Footer-Text:hover,
.Footer-Text:focus,
.Footer-Text:active,
.Footer-Text-Right,
.Footer-Text-Right:hover,
.Footer-Text-Right:focus,
.Footer-Text-Right:active {
    color: #FFFFFF !important;
}

.Footer-Text-Box {
    display: flex;
    justify-content: flex-end;
    margin-left: 20px;
}

.Footer-Copyright-Box {
    display: flex;
    justify-content: flex-end;
}




/* Homepage Classes */
.Homepage-Intro-Text {
    font-family: DIN-Bold;
    font-size: 3rem;
    color: #FFFFFF;
    user-select: none;
}

.Homepage-About-Text {
    text-align: left;
    font-family: DIN-Regular;
    font-size: 1.5rem;
    color: #FFFFFF;
    user-select: none;
}

.Homepage-About-Text-Animated {
    text-align: left;
    font-family: DIN-Regular;
    font-size: 1.5rem;
    color: #FFE443;
    user-select: none;
}

.Homepage-Parent-Container {
    min-height: calc(100vh - 160px);
    min-height: calc(100svh - 160px);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Homepage-Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    margin: 2rem;
}

.Homepage-Container>.Vertical-Line-1 {
    border-left: 5px solid #FFFFFF;
    height: 220px;
    margin-left: 80px;
    margin-right: 20px;
    transform: rotate(20deg);
    transform-origin: left;
}

.Homepage-Container>.Vertical-Line-2 {
    border-left: 5px solid #FFFFFF;
    height: 220px;
    margin-left: 20px;
    margin-right: 80px;
    transform: rotate(20deg);
    transform-origin: left;
}

.Homepage-Container>*:not(.Vertical-Line-1, .Vertical-Line-2) {
    flex: 1;
    text-align: justify;
}

.Homepage-Loading-Container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}

.Homepage-Loading-Text {
    font-family: DIN-Medium;
    color: #FFFFFF;
    font-size: 3rem;
}

.Homepage-Loading-Progress {
    color: #FFE443;
}

@media (min-width: 700px) {
    .Homepage-Intro-Text {
        text-align: right;
        font-size: 2.5rem;
    }

    .Homepage-About-Text {
        font-size: 1.3rem;
    }

    .Homepage-About-Text-Animated {
        font-size: 1.3rem;
    }

    .Homepage-Container {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-right: 10%;
    }

    .Homepage-Container>.Vertical-Line-1 {
        height: 180px;
        margin-left: 3rem;
        margin-right: 0.8rem;
    }

    .Homepage-Container>.Vertical-Line-2 {
        height: 180px;
        margin-right: 3rem;
        margin-left: 0.8rem;
    }
}

@media (min-width: 1100px) {
    .Homepage-Intro-Text {
        font-size: 3.2rem;
    }

    .Homepage-About-Text {
        font-size: 2.5rem;
    }

    .Homepage-About-Text-Animated {
        font-size: 2.5rem;
    }

    .Homepage-Container>.Vertical-Line-1 {
        height: 220px;
        margin-left: 3rem;
        margin-right: 1rem;
    }

    .Homepage-Container>.Vertical-Line-2 {
        height: 220px;
        margin-left: 1rem;
        margin-right: 3rem;
    }
}



/* Invalid Page Classes */
.Invalid-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: calc(100svh - 160px);
}




/* Contact Page Classes */
.Contact-Container-Flex {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    /* gap: 2vw; */
}

.Contact-Column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.Contact-Icons-Box {
    display: flex;
    justify-content: flex-start;
}

.Contact-Icons {
    color: #FFE443;
    margin: 10px;
}

.Contact-Top-Space {
    margin-top: 20px;
}

.Contact-Header-Top-Space {
    margin-top: 10px;
}

.Contact-Submit-Button-Positioning {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.Phone-Modal-Header {
    font-family: DIN-Bold;
    color: #0F1120;
}

.Phone-Modal-Body {
    font-family: DIN-Regular;
    color: #0F1120;
}

@media (min-width: 1000px) {
    .Contact-Column {
        width: 100%;
    }
}




/* Resume Component Classes */
.Resume-Title-Icon-Container {
    display: flex;
}

.Resume-Download-Icon {
    color: #FFE443;
}

.Resume-Body-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.Resume-Main-Column {
    display: flex;
    flex-direction: column;
}

.Resume-Secondary-Column {
    display: flex;
    flex-direction: column;
}

.Resume-Content-Column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Resume-Header-Container {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
}

.Resume-Header-Title-Container {
    display: flex;
}

.Resume-List-Margin {
    margin-bottom: 2rem;
}

.Resume-Text-Divider {
    border-left: 1px solid #FFFFFF;
    height: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    justify-content: center;
}

.Resume-Body-Text {
    font-family: DIN-Regular;
    font-size: 0.65rem;
    color: #FFFFFF;
    list-style: disc;
}

.Resume-Body-Text-Bold {
    font-family: DIN-Bold;
    font-size: 0.65rem;
    color: #FFFFFF;
}

.Resume-Body-Text-Right {
    font-family: DIN-Regular;
    justify-content: right;
    font-size: 0.65rem;
    color: #FFFFFF;
}

.Resume-Job-Subtitle {
    line-height: 0.8rem;
}

@media (min-width: 700px) {
    .Resume-Text-Divider {
        height: 1.3rem;
    }

    .Resume-Body-Text,
    .Resume-Body-Text-Bold,
    .Resume-Body-Text-Right {
        font-size: 0.85rem;
    }

    .Resume-Job-Subtitle {
        line-height: 1rem;

    }
}

@media (min-width: 1050px) {
    .Resume-Body-Container {
        flex-direction: row;
        justify-content: space-between;
    }

    .Resume-Main-Column {
        width: 60%;
    }

    .Resume-Secondary-Column {
        width: 30%;
    }
}




/* About Component Classes */
.About-Avatar-Image {
    border-radius: 50%;
    max-height: 24rem;
}

@media (max-width: 999px) {
    .About-Avatar-Image {
        margin-bottom: 5rem;
        max-width: 60%;
    }
}

@media (min-width: 1000px) {
    .About-Avatar-Image {
        max-width: 100%;
        max-height: 120rem;
    }
}




/* Work Component Classes */
.Work-Project-Image {
    max-width: 100%;
    margin-bottom: 4rem;
}

.Work-Horizontal-Section-Divider {
    position: relative;
    border-top: 1px solid #FFFFFF;
    margin-top: 9rem;
    margin-bottom: 9rem;
}

.Work-Vimeo-Embed-Portrait {
    height: 500px;
    margin-bottom: 4rem;
}

.Work-Vimeo-Embed-Landscape {
    flex: 1;
    width: 100%;
    margin-bottom: 4rem;
}

@media (min-width: 1000px) {

    .Work-Vimeo-Embed-Portrait,
    .Work-Vimeo-Embed-Landscape,
    .WorkProject-Image {
        margin-bottom: 0;
    }
}

@media (max-width: 999px) {
    .Work-Vimeo-Embed-Landscape {
        min-height: 400px;
    }
}




/* Blog Component Classes */
.Blog-Post-Image {
    max-width: 100%;
}

.Blog-Body-Text {
    font-family: DIN-Regular;
    font-size: 0.85rem;
    color: #FFFFFF;
    list-style: disc;
}

.Blog-Image-Caption-Text {
    font-family: DIN-Regular;
    font-size: 0.65rem;
    color: #FFFFFF;
}

@media (min-width: 700px) {
    .Blog-Body-Text {
        font-size: 1rem;
    }

    .Blog-Image-Caption-Text {
        font-size: 0.85rem;
    }
}



/* PM Project Work List Component Classes */
.PM-Work-Project-Image {
    max-width: 100%;
    margin-bottom: 4rem;
    max-height: 16rem;
}

.PM-Project-Title-Link-Container {
    display: flex;
    flex-direction: row;
    width: fit-content;
}

.PM-Project-Image-Link {
    cursor: pointer;
}

.PM-Project-Title-Link {
    font-family: DIN-Bold;
    font-size: 40px;
    text-align: left;
    color: #FFFFFF;
    user-select: none;
    cursor: pointer;
    transition: color 0.3s;
}

.PM-Project-Title-Link.active {
    color: #FFE443;
}

.PM-Project-Title-Link:hover {
    color: #FFE443;
}



/* PM Project Detail Component Classes */
.PM-Detail-Body-Container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.PM-Detail-Main-Column {
    display: flex;
    flex-direction: column;
}

.PM-Detail-Secondary-Column {
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, .1);
    padding: 3rem;
    height: fit-content;
}

.PM-Detail-Header-Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (min-width: 1050px) {
    .PM-Detail-Body-Container {
        flex-direction: row;
        justify-content: space-between;
    }

    .PM-Detail-Main-Column {
        width: 60%
    }

    .PM-Detail-Secondary-Column {
        width: 30%;
    }
}
